
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

const interval: number = 25000;

export default Vue.extend({
    data() {
        return {
            polls: [],
            poll: { name: "", id: 0 },
            questions: [],
            responses: {},
            currentPollIndex: 0,
            currentQuestionIndex: 0,
            showSurvey: false,
            emojiList: ["😡", "😕", "😐", "😊", "😁"],
            isLoading: false,
        };
    },

    mounted() {
        this.setupInterval();
        console.log("mounted: ",this.isLoading)
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    computed: {
        ...mapGetters("profile", ["account"]),
        ...mapGetters('Polls', ['getPolls']),
        ...mapGetters("auth", ["isLoggedIn"]),

        sliderColor() {
            const value = this.responses[this.currentQuestion?.id];
            switch (true) {
                case value <= 2:
                    return "#ff3d00";
                case value <= 4:
                    return "#ff9100";
                case value <= 6:
                    return "#ffea00";
                case value <= 8:
                    return "#64dd17";
                case value <= 10:
                    return "#2962ff";
                default:
                    return "#9e9e9e";
            }
        },

        currentQuestion() {
            return this.questions[this.currentQuestionIndex];
        },

        isLastQuestion() {
            return this.currentQuestionIndex === this.questions?.length - 1;
        },
    },

    methods: {
        ...mapActions("Polls", ["fetchPolls", "fetchQuestionsByPollId", "pollCancel", "submitPollResponses"]),

        setupInterval() {
            this.timer = setInterval(() => {
                if (this.isLoading === false) {
                    this.loadPolls();
                }
                
            }, interval);
        },

        updateSliderValue(value) {
            this.$set(this.responses, this.currentQuestion.id, value);
        },

        async loadPolls() {
            this.isLoading = true;
            try {
                const response = await this.fetchPolls();
                if (response.length > 0) {
                    this.polls = response;
                    this.poll = this.polls[this.currentPollIndex];
                    await this.loadQuestionsForCurrentPoll();
                    this.showSurvey = true;
                } else {
                    this.isLoading = false;
                    console.warn("No se encontraron encuestas");
                }
            } catch (error) {
                console.error("Error al obtener encuestas:", error);
            } 
        },

        async submitResponses() {
            const createPayload = () => ({
                poll_id: this.poll.id,
                responses: Object.keys(this.responses).map((question_id) => ({
                    question_id: parseInt(question_id),
                    response: typeof this.responses[question_id] === 'number' ? this.responses[question_id] : 0,
                })),
            });

            try {
                const payload = createPayload();
                await this.submitPollResponses(payload);
                this.resetSurveyData(); 
            } catch (error) {
                console.error("Error al enviar las respuestas:", error);
            }
        },

        resetSurveyData() {
            Object.keys(this.responses).forEach((question_id) => {
                this.responses[question_id] = 0;
            });
            this.isLoading = false;
            this.responses = {};
            this.questions = [];
            this.currentQuestionIndex = 0;
            this.showSurvey = false;
            this.poll = { name: "", id: 0 };
        },

        cancelSurvey() {
            this.pollCancel(this.poll.id)
            this.resetSurveyData();
            this.showSurvey = false;
        },

        async loadQuestionsForCurrentPoll() {
            const questions = await this.fetchQuestionsByPollId(this.poll.id);
            this.questions = questions;
            this.initializeResponses();
        },

        initializeResponses() {
            this.questions.forEach((question) => {
                this.$set(this.responses, question.id, question.type === "scale" ? 5 : "");
            });
        },

        getQuestionText(question) {
            const language = this.$store.state.internationalization.language.toUpperCase();
            return question[`question_${language}`] || question.question_EN;
        },

        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            } else if (this.currentPollIndex < this.polls.length - 1) {
                this.currentPollIndex++;
                this.currentQuestionIndex = 0;
                this.poll = this.polls[this.currentPollIndex];
                this.loadQuestionsForCurrentPoll();
            }
        },

        isSelectedEmoji(index) {
            const value = this.responses[this.currentQuestion?.id];
            switch (index) {
                case 1:
                    return value >= 1 && value <= 2;
                case 2:
                    return value >= 3 && value <= 4;
                case 3:
                    return value >= 5 && value <= 6;
                case 4:
                    return value >= 7 && value <= 8;
                case 5:
                    return value >= 9 && value <= 10;
                default:
                    return false;
            }
        },
    },

    watch: {
        account: {
            handler() {
                this.resetSurveyData()
                this.loadPolls()

            },
        },
    },
});
